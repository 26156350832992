import styled, { css } from 'styled-components'

export const Service = styled.article``

export const Text = styled.div`
  padding-top: ${({ theme }) => theme.gutter};
`

export const EmptyIcon = styled.div`
  width: ${({ theme }) => theme.iconSize};
  height: ${({ theme }) => theme.iconSize};
`