import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Services } from './styles'
import ServiceCard from '../ServiceCard'

export const query = graphql`
  fragment ServicesCardsFragment on DatoCmsService {
    id
    icon {
      title
    }
    textContent
  }
`

const ServicesCards = ({ services }) => (
  <Services>
    {services.map(({ id, textContent, icon }) => (
      <ServiceCard
        as="li"
        key={id}
        textContent={textContent}
        icon={icon}
      />
    ))}
  </Services>
)

export default ServicesCards

ServicesCards.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired,
      textContent: PropTypes.string.isRequired
    })
  ).isRequired
}
