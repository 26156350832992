import styled from 'styled-components'
import { breakpoint, ContentAlign, SectionAlignStyles } from '../../layout/mixins'
import AuthorImage from '../../components/AuthorImage'
import Button from '../../components/Button'

export const Authors = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 25%);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: ${({ theme }) => theme.gutter};
  padding-left: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    transform: translate(-50%, 50%);
  `}
`

export const StyledAuthorImage = styled(AuthorImage)`
  flex: 0 0 ${({ theme }) => theme.portraitSize};
  margin-right: ${({ theme }) => theme.gutterSmall};
  margin-bottom:  ${({ theme }) => theme.gutterSmall};
`
export const Content = styled(ContentAlign)`
  padding-top: ${({ theme }) => theme.gutterXXLarge};
  padding-bottom: ${({ theme }) => theme.gutterXXLarge};

`

export const Preamble = styled.div`
  padding-top: ${({ theme }) => theme.gutter};
  padding-bottom: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    padding-top: ${({ theme }) => theme.gutterXXLarge};
    padding-bottom: ${({ theme }) => theme.gutterXXLarge};
    text-align: center;
  `}
`

export const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(4, 308px);
  column-gap: ${({ theme }) => theme.gutter};
  row-gap: ${({ theme }) => theme.gutter};
  margin-bottom: ${({ theme }) => theme.gutterXXLarge};

  ${breakpoint.up('phone')`
    grid-template-columns: repeat(2, minmax(427px, 1fr));
    grid-template-rows: repeat(2, minmax(308px, 1fr));
  `}
`

export const HighlightedSection = styled.section`
  background: ${({ theme }) => theme.backgroundColorAlt};
`
export const Text = styled.div`
  padding-bottom: ${({ theme }) => theme.gutterXXLarge};
`

export const BackgroundSection = styled.div`
  ${SectionAlignStyles}
  background: ${({ theme }) => theme.backgroundColorAlt};
`

export const StyledButton = styled(Button)`
  margin: 0 auto;
`