import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Service, Text, EmptyIcon
} from './styles'
import slugify from 'slugify'


const ServiceCard = (props) => {
  
  const {
    textContent,
    as
  } = props

  const [IconComponent, setIconComponent] = useState(null)
  const icon = slugify(props?.icon.title, {lower: true})

  useEffect(() => {

    if(!icon) { 
      return
    }

    import(`../../layout/icons/${icon}.svg`)
      .then((module) => {
        const StyledIcon = styled(module.default)`
          fill: ${({ theme }) => theme.colorAccentDark};
          width: ${({ theme }) => theme.iconSize};
          height: ${({ theme }) => theme.iconSize};
        `
        setIconComponent(StyledIcon)
      })
      .catch(err => {
        setIconComponent(EmptyIcon)
      })

  }, [])

  return (
    <Service data-cy="service-card" as={as}>
      {IconComponent && <IconComponent />}
      <Text className="rte" dangerouslySetInnerHTML={{ __html: textContent }} />
    </Service>
  )
}

export default ServiceCard

ServiceCard.propTypes = {
  icon: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  textContent: PropTypes.string.isRequired
}
