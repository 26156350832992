import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import {
  Authors, StyledAuthorImage, Content, Preamble, ArticleGrid, HighlightedSection, Text, StyledButton
} from './styles'
import { Heading2, StyledTippy } from '../../layout/mixins'
import ArticleCard from '../../components/ArticleCard'
import ServicesCards from '../../components/ServicesCards'
import Toggle from '../../components/Toggle'
import SubscribeModal from '../../components/SubscribeModal'


const Home = ({ data }) => {
  const {
    content: {
      heroImage,
      authors,
      preamble,
      headline,
      subheadline,
      textContent,
      services,
      seoMetaTags
    },
    articles
  } = data

  return (
    <Layout seo={seoMetaTags.tags}>
      <Hero backgroundImage={heroImage}>
        {authors ? (
          <Authors>
            {authors.map((author) => (
              <StyledTippy
                placement="bottom"
                arrow={false}
                key={author.name}
                content={author.name}
              >
                <StyledAuthorImage image={author.image} />
              </StyledTippy>
            ))}
          </Authors>
        ) : null}
      </Hero>
      <Content>
        <Preamble data-cy="frontpage-preamble" className="rte rte--large" dangerouslySetInnerHTML={{ __html: preamble }} />
        <ArticleGrid>
          {articles.edges.map(({ node }) => (
            <ArticleCard
              key={node.slug}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...node}
            />
          ))}
        </ArticleGrid>
        <Toggle>
          {(on, toggle) => (
            <>
              <StyledButton onClick={toggle} data-cy="toggle-subscribe" text="Meddela mig när nästa artikel publiceras" arrow />
              {on && (
                <SubscribeModal
                  toggle={toggle}
                />
              )}
            </>
          )}
        </Toggle>
      </Content>
      <HighlightedSection>
        <Content>
          <Heading2>{headline}</Heading2>
          <Text className="rte rte--large" dangerouslySetInnerHTML={{ __html: textContent }} />
          <Heading2>{subheadline}</Heading2>
          <ServicesCards services={services} />
        </Content>
      </HighlightedSection>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query FrontpageQuery {
    content: datoCmsFrontpage {
      authors {
        company
        name
        title
        image {
          ...AuthorImageFragment
        }
      }
      preamble
      heroImage {
        ...HeroImageFragment
      }
      seoMetaTags {
        tags
      }
      textContent
      subheadline
      headline
      services {
        ...ServicesCardsFragment
      }
    }
    articles: allDatoCmsArticle(sort: {fields: publishWeek}) {
      edges {
        node {
          id
          slug
          title
          publishWeek
          meta {
            status
          }
          thumbnail {
            fluid(maxWidth: 2048, imgixParams: { fm: "jpg", auto: "compress" }, resizes: [375, 750, 850, 1024, 1700, 2048]) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
