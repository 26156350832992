import styled from 'styled-components'
import { breakpoint } from '../../layout/mixins'

export const Services = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${({ theme }) => theme.gutter};
  row-gap: ${({ theme }) => theme.gutterMedium};
  padding-top: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    grid-template-columns: repeat(3, 1fr);
    row-gap: ${({ theme }) => theme.gutterLarge};
  `}

`
